import {INetworkComponent} from "../../../network/types/INetworkComponent";
import {Network} from "../../../network/Network";
import {HttpStatus} from "../../../network/status/HttpStatus";
import {SiteService} from "../../site/SiteService";
import {AccountService} from "../AccountService";
import {IInvitationModel} from "../../../models/invitation/IInvitationModel";
import {Resources} from "../../../resources/Resources";
import {EntityService} from "../../entity/EntityService";
import {AccountPreferencesService} from "../preferences/AccountPreferencesService";
import {RoleAccountService} from "../../roles/account/RoleAccountService";
import {NotificationCountAccountService} from "../../notification/count/account/NotificationCountAccountService";
import {ProductType} from "../../../models/product/ProductType";
import {Services} from "../../Services";
import {AccountComposerService} from "../composers/AccountComposerService";
import {ChannelService} from "../../channel/ChannelService";
import {SiteColumnsContactService} from "../../site/columns/contact/SiteColumnsContactService";
import {OfferProductService} from "../../offer/product/OfferProductService";
import {getParam} from "../../../sedestral-interface-modules/sedestral-interface-component/utilities/Url";

export class AccountSessionService {


    public static dispose(): void {
    }

    public static init(): void {
    }

    /**
     * rest
     */
    public static async token(): Promise<boolean> {
        try {

            if (getParam("transaction") != null) {
                if (EntityService.activeToken) {
                    EntityService.saveActiveToken = EntityService.activeToken;
                    EntityService.clearActiveToken();
                }
            }

            let tokenRequest = await Network.post(ProductType.PANEL, "/account/session/token");
            if (tokenRequest.status != HttpStatus.OK) {
                return false;
            }

            Network.logged = tokenRequest.data.online;
            EntityService.headersToken(tokenRequest.headers);
            //SiteColumnsContactService.storeStandard(tokenRequest.data.standardColumnsContacts);

            if (Network.logged) {
                ChannelService.storeAll(tokenRequest.data.channels);
                EntityService.activeOfferIsFailureToPay = tokenRequest.data.activeOfferIsFailureToPay;
                EntityService.activeOfferProducts = OfferProductService.storeAllObject(tokenRequest.data.products);
                EntityService.activeEntity = AccountService.store(tokenRequest.data.account);
                EntityService.activeEntity['preferences'] = AccountPreferencesService.store(tokenRequest.data.preferences);
                EntityService.activeEntity['rolesAccount'] = RoleAccountService.storeAll(tokenRequest.data.rolesAccount);
                EntityService.activeEntity['composers'] = AccountComposerService.storeAll(tokenRequest.data.composers);
                EntityService.activeSite = SiteService.store(tokenRequest.data.site);
                EntityService.activeNotifications = tokenRequest.data.notifications != undefined ? NotificationCountAccountService.store(tokenRequest.data.notifications) : NotificationCountAccountService.virtual();
            }

            return true;
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    public static async login(mail: string, password: string, invitation?: IInvitationModel, component?: INetworkComponent) {
        Services.handleErrors(component, [
            {status: HttpStatus.UNAUTHORIZED, message: Resources.t("words.wrongIdentifiers")}
        ]);

        let request = await Network.post(ProductType.PANEL, "/account/session/login",
            {
                email: mail,
                password: password,
                invitationId: invitation == undefined ? undefined : invitation.id
            }, component);

        return request.status == HttpStatus.OK;
    }

    public static async logout(component?: INetworkComponent) {
        let request = await Network.post(ProductType.PANEL, "/account/session/logout", "", component);
        if (request.status == HttpStatus.OK) {
            await AccountSessionService.createLogout();
        }
    }

    /**
     * on events
     */
    public static async onLogin() {
        await SiteService.join(EntityService.activeEntity.siteId);
    }

    public static onLogout() {
        SiteService.leave(EntityService.activeEntity.siteId);
    }

    public static async createLogout() {
        Network.router.onLogout();
        await Network.onLogout();
        EntityService.clearActiveToken();
    }

}