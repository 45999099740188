import {Network} from "../../../network/Network";
import {ProductType} from "../../../models/product/ProductType";
import {INetworkComponent} from "../../../network/types/INetworkComponent";
import {ICommunityGscResponseIncoming} from "../../../models/community/gsc/ICommunityGscResponseIncoming";
import {HttpStatus} from "../../../network/status/HttpStatus";
import {CommunityGscVerificationType} from "../../../models/community/gsc/CommunityGscVerificationType";

export class CommunityGscService {

    public static dispose(): void {}

    public static init(): void {}

    public static async getGsc(communityId: string, component?: INetworkComponent): Promise<ICommunityGscResponseIncoming> {
        let request = await Network.get(ProductType.COMMUNITY, `/community/gsc/${communityId}`, component);
        return request.data;
    }

    public static async disconnect(communityId: string, component?: INetworkComponent): Promise<boolean> {
        let request = await Network.post(ProductType.COMMUNITY, `/community/gsc/disconnect/${communityId}`, component);
        return request.status == HttpStatus.OK;
    }

    public static async verification(communityId: string, verificationType: CommunityGscVerificationType, component?: INetworkComponent): Promise<boolean> {
        let request = await Network.post(ProductType.COMMUNITY, `/community/gsc/verification/${verificationType}/${communityId}`, component);
        return request.status == HttpStatus.OK;
    }
}