import {VisualModalComponent} from "../../../sedestral-interface-component-visual/modal/VisualModalComponent";
import * as s from "./prompt-confirmation.scss";
import {Component} from "../../../sedestral-interface-component/interface/component/Component";
import {LoaderLightComponent} from "../../loader/light/LoaderLightComponent";
import {Resources} from "../../../../resources/Resources";

export class PromptConfirmationComponent extends VisualModalComponent {

    private continueButton: Component;
    private cancelButton: Component;
    private readonly okButton: boolean;

    constructor(description: string, name?: string, okButton?: boolean) {
        super();
        this.name = name ?? Resources.t("words.confirmation");
        this.okButton = okButton;
        //language=HTML
        this.modalTemplate = `
            <div class="${s.globalPromptConfirmation}">
                <div class="${s.description}">
                    ${description}
                </div>
                <div class="${s.buttons}">
                    ${this.okButton ? '' : `<div class="${s.button} ${s.cancel}">
                        ${Resources.t("words.cancel")}
                    </div>`}
                    <div class="${s.button} ${s.continue}">
                        ${this.okButton ? Resources.t("words.ok") : Resources.t("words.continue")}
                    </div>
                </div>
            </div>
        `;
    }

    commit() {
        super.commit();
        this.visualModal.setStyle(`z-index:99999999;`)
        this.boxContainer.setStyle(`width:250px;`);
        this.cancelButton = this.el(s.cancel);
        this.continueButton = this.el(s.continue);

        if (!this.okButton) {
            this.cancelButton.onClick(async () => {
                await this.close();
            });
            this.continueButton.onClick(() => {
                this.onContinue();
            });
        } else {
            this.continueButton.onClick(async () => {
                await this.close();
            });
        }
    }

    setLoading() {
        this.continueButton.save();
        this.continueButton.clearAll();
        this.continueButton.render(new LoaderLightComponent());
    }

    /**
     * override
     */

    onContinue() {}
}