import {Component} from "../../sedestral-interface-component/interface/component/Component";
import * as s from "./progressbar.scss";
import {IProgressBar} from "./types/IProgressBar";
import {SedestralMachine} from "../../sedestral-interface-component/machine/SedestralMachine";

export class ProgressBarComponent extends Component {

    public barComponent: Component;
    public p: IProgressBar;

    constructor(p: IProgressBar) {
        super();
        this.p = p;

        //language=HTML
        this.template = `
            <div data-tooltip="${p.tooltip}" class="${s.globalProgressBar} ${p.white ? s.white : ``}">
                <div class="${s.bar} ${p.color ? s[p.color] : ``}"></div>
                ${p.showValue ? `<div class="${s.percent}">50%</div>` : ``}
            </div>
        `;
    }

    commit() {
        this.barComponent = this.el(s.bar);

        if (this.p.tooltip) {
            super.commitTooltips();
        }

        if (this.p.autoColor) {
            this.barComponent.addClass(s[this.getColor()]);
        }

        let width = Math.round(this.p.value * 100 / this.p.maxValue);

        this.barComponent.setStyle(`width:0%;`);
        SedestralMachine.requestFrame()(() => {
            this.barComponent.setStyle(`width:${width}%;transition-duration: ${this.p.animationDelay / 1000}s;`);
        });


        super.commit();
    }

    getColor(): string {
        const percentage = (this.p.value / this.p.maxValue) * 100;

        if (percentage < 30) {
            return "red";
        } else if (percentage >= 30 && percentage <= 65) {
            return "orange";
        } else {
            return "green";
        }
    }
}