import 'regenerator-runtime/runtime';
import {SedestralLogic} from "./sedestral-interface-modules/sedestral-interface-component/SedestralLogic";
import {config} from "./config";
import {Network} from "./network/Network";
import {Website} from "./products/website/Website";
import {WebsiteNetwork} from "./products/website/network/WebsiteNetwork";
import {ProductName} from "./models/product/ProductName";
import {ProductType} from "./models/product/ProductType";
import {SedestralSsr} from "./sedestral-interface-modules/sedestral-interface-component/ssr/SedestralSsr";
import {Resources} from "./resources/Resources";
import {EntityService} from "./services/entity/EntityService";
import {AccountService} from "./services/account/AccountService";
import Hotjar from '@hotjar/browser';
import {loadScript} from "./sedestral-interface-modules/sedestral-interface-component/utilities/Script";

config.import("website");
SedestralLogic.ready(async () => {
    await SedestralLogic.init();
    await WebsiteNetwork.init();
    await Network.init(ProductName.toString(ProductType.WEBSITE));
    await Website.init();

    if (!SedestralSsr.hasSsr() && !/Googlebot|Spiders|Crawlers/i.test(navigator.userAgent)) {
        loadScript("https://js-na1.hs-scripts.com/48111012.js", () => {});

        try {
            if (!config.isDev && !SedestralSsr.hasSsr()) {
                const hotjarId = config.hotjarId;
                const hotjarVersion = 6;
                Hotjar.init(Number(hotjarId), hotjarVersion);
            }
        } catch (e) {
            console.error(e);
        }

    }
});