import {
    Component
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "../website-content.scss";
import * as r from "../statistics/website-content-statistics.scss";
import {
    WebsiteIssuesComponent
} from "../../issues/WebsiteIssuesComponent";
import {Resources} from "../../../../../../../resources/Resources";
import {WebsiteColumnsGridWhiteComponent} from "../../columns/grid/white/WebsiteColumnsGridWhiteComponent";
import {WebsiteColumnsDoubleComponent} from "../../columns/double/WebsiteColumnsDoubleComponent";
import {NetworkVendor} from "../../../../../../../network/NetworkVendor";
import {WebsiteBlackStepsComponent} from "../../../black/steps/WebsiteBlackStepsComponent";
import {WebsiteBlackPresentationComponent} from "../../../black/presentation/WebsiteBlackPresentationComponent";

export class WebsiteContentBlogAutopilotComponent extends Component {
    constructor() {
        super();
        //language=HTML
        this.template = `
            <div class="${s.componentWebsiteContent}">
                <div class="${s.content} ${r.componentWebsiteContentStatistics}">
                    <div class="${s.container}">
                        ${this.draw(new WebsiteBlackPresentationComponent({
                            startText: Resources.t("words.beginFree"),
                            title: Resources.t("words.automateBlogManagement"),
                            description: Resources.t("words.aiHandlesEverything"),
                            people: false,
                            center: true
                        }))}
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsiteBlackStepsComponent({
                            title: Resources.t("words.autopilotConfiguration"),
                            icon: "seo",
                            center: true,
                            contents: [
                                {
                                    name: Resources.t("words.autopilot"),
                                    type: "video",
                                    path: NetworkVendor.image("blog-video-c.mp4"),
                                    duration: 7000
                                }
                            ]
                        }))}
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsiteColumnsDoubleComponent({
                            title: Resources.t("words.howItWorks"),
                            columns: [{
                                icon: "articleAi",
                                name: Resources.t("words.intelligentCreation"),
                                text: Resources.t("words.aiThinksAndWrites"),
                            }, {
                                icon: "letter",
                                name: Resources.t("words.personalizedDelivery"),
                                text: Resources.t("words.chooseDelivery")
                            }]
                        }))}
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsiteColumnsGridWhiteComponent({
                            title: Resources.t("words.whyUseAutopilot"),
                            color: "black",
                            details: [{
                                icon: "black-lineal-time",
                                title: Resources.t("words.savePreciousTime"),
                                subtitle: Resources.t("words.aiHandlesResearchAndWriting")
                            }, {
                                icon: "black-chart",
                                title: Resources.t("words.boostVisibility"),
                                subtitle: Resources.t("words.maximizeSEOPositioning")
                            }, {
                                icon: "black-lineal-good",
                                title: Resources.t("words.ensureConstantPublication"),
                                subtitle: Resources.t("words.freshContentAlignedWithTrends")
                            }]
                        }))}
                    </div>
                    <div class="${s.container}">
                        <div class="${r.reportsContainer}">
                        <div class="${r.reports}">
                            <div class="${r.right}">
                                <img class="${r.image}" src="${NetworkVendor.image("blog-e.webp")}"
                                     alt="styleCloning" draggable="false"
                                     width="380" height="347.51"
                                />
                            </div>
                            <div class="${r.left}">
                                <h2 class="${r.title}">
                                    ${Resources.t("words.keepWritingStyleTitle")}
                                </h2>
                                <p class="${r.subtitle}">
                                    ${Resources.t("words.keepWritingStyleText")}
                                </p>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsiteIssuesComponent({
                            theme: "black",
                            "questions": [
                                {
                                    "question": Resources.t("words.howAiGeneratesIdeas"),
                                    "response": Resources.t("words.aiAnalyzesIndustry")
                                },
                                {
                                    "question": Resources.t("words.areContentsSEOOptimized"),
                                    "response": Resources.t("words.articlesSEOOptimized")
                                },
                                {
                                    "question": Resources.t("words.canChooseArticleFrequency"),
                                    "response": Resources.t("words.controlArticleFrequency")
                                },
                                {
                                    "question": Resources.t("words.howArecContentsDelivered"),
                                    "response": Resources.t("words.chooseArticleDelivery")
                                },
                                {
                                    "question": Resources.t("words.canModifyContentsBeforePublication"),
                                    "response": Resources.t("words.reviseAndPersonalizeArticles")
                                },
                                {
                                    "question": Resources.t("words.canAiAdaptToDifferentContentTypes"),
                                    "response": Resources.t("words.aiAdaptsToVariousContentTypes")
                                },
                                {
                                    "question": Resources.t("words.howAiUnderstandsBusiness"),
                                    "response": Resources.t("words.provideInfoForAiToAdapt")
                                },
                                {
                                    "question": Resources.t("words.doesAiMaintainBrandTone"),
                                    "response": Resources.t("words.aiMaintainsBrandTone")
                                },
                                {
                                    "question": Resources.t("words.whoCanUseAutopilot"),
                                    "response": Resources.t("words.autopilotForAllBusinesses")
                                },
                                {
                                    "question": Resources.t("words.canModifyOrStopServiceAnytime"),
                                    "response": Resources.t("words.freedomToModifyOrStopService")
                                }
                            ]
                        }))}
                    </div>
                </div>
            </div>
        `;
    }
}