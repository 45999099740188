import {
    Component
} from "../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {ProductType} from "../../../../../models/product/ProductType";
import {ProductName} from "../../../../../models/product/ProductName";
import {CapsuleIconComponent} from "./icon/CapsuleIconComponent";
import * as s from "./capsule.scss";
import {
    TextResizeableComponent
} from "../../../../../sedestral-interface-modules/sedestral-interface-component-global/text/resizeable/TextResizeableComponent";
import {Resources} from "../../../../../resources/Resources";
import {PermissionsService} from "../../../../../services/permissions/PermissionsService";
import {EntityService} from "../../../../../services/entity/EntityService";

export class CapsuleComponent extends Component {

    public productType: ProductType;
    public soon: boolean;

    constructor(type: ProductType, soon?: boolean) {
        super();
        this.productType = type;
        this.soon = soon ? soon : false;

        let style = "";
        switch (type) {
            case ProductType.SEDESTRAL:
            case ProductType.GENERAL:
                style = "width:50%;height:50%;";
                break;
            case ProductType.MAILS:
            case ProductType.CHAT_BOT:
            case ProductType.MAILLING:
            case ProductType.ENGAGEMENT:
                style = "width:46%;height:46%;";
                break;
            case ProductType.ADVERTISING:
            case ProductType.INBOX:
            case ProductType.MEDIAS:
                style = "width:42%;height:42%;";
                break;

        }

        //language=HTML
        this.template = `
            <div class="${s.componentCapsule} ${s[ProductName.toString(type)]}">
                <div style="${style}" class="${s.icon}">
                    ${this.draw(new CapsuleIconComponent(type))}
                </div>
            </div>
        `;
    }

    commit() {
        switch (this.productType) {
            case ProductType.CALENDAR:
            case ProductType.ADVERTISING:
            case ProductType.CHAT_BOT:
            case ProductType.SELL:
            case ProductType.MAILLING:
            case ProductType.MEDIAS:
            case ProductType.ENGAGEMENT:
            case ProductType.ESTIMATE:
            case ProductType.DOCUMENTS:
            case ProductType.PRODUCTS:
                this.renderSoon();
                break;
        }
        super.commit();
    }

    renderSoon() {
        if (this.soon !== true) {
            //language=HTML
            this.append(`
                <div class="${s.soon}">
                    <div class="${s.position}">
                        ${this.draw(new TextResizeableComponent(Resources.t("words.soon"), "white"))}
                    </div>
                </div>
            `);
        }
    }

}